import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Footer from "../components/Footers/Footer";
import FooterMobile from "../components/Footers/FooterMobile";
import close from "../images/close.svg";
import logo from "../images/Manage-Autism-Logo.svg";
import axios from "axios";

const SignUp = () => {

  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    location:"",
    patientFirstName:"",
    patientSurnameName:"",
    patientAge:"",
    diagnosisADD: "",
    diagnosisADHD: "",
    diagnosisAnxiety: "",
    diagnososASD: "",
    diagnosisAsperger: "",
    diagnosisAuditoryProcessing: "",
    diagnosisDepression: "",
    diagnosisDevCoordinationDisorder: "",
    diagnosisDownSyndrome: "",
    diagnosisEpilepsy: "",
    diagnosisFineMotor: "",
    diagnosisGastro: "",
    diagnosisGifted: "",
    diagnosisOCD: "",
    diagnosisSensoryIntegrationDisorder: "",
    diagnosisSpecificLearningDifficulties: "",
    diagnosisSpeechDelays: "",
    otherDiagnosis:"",
    childsTreatment: "",
    behaviouralTeam:"",
    communityPaediatrician:"",
    development:"",
    dieticians:"",
    disabilitySupportWorker:"",
    gastroIntestinal:"",
    generalPaediatrician:"",
    holisticDoctor:"",
    kinesiologist:"",
    occupationalTherapist:"",
    physiotherapist:"",
    psychologist:"",
    socialWorker:"",
    otherTreatment:"",
    childsLookedAfterBy:"",
    childsTreatmentCostPounds:"",
    siteLikes:"",
    designLikes:"",
    userServiceFeedback:"",
    serviceLikes:"",
    serviceDislikes:"",
    helpInfo:"",
    otherInfo:"",
    servicePayFeedback:"",
    serviceRecommendFeedback:"",
    shareInfoHealthCare:"",
    shareInfoSchool:"",
    similarProduct:"",
    loggingInformation:"",
    patientsASD:"",
    rating1:"",
    rating2:"",
    rating3:"",
    rating4:"",
    rating5:"",
  })

  const navigate = useNavigate();
  const formSubmit = (e) => {
    e.preventDefault();
    
    if (values.email !== values.confirmEmail) {
      alert('Emails must match');
      return;
    }

    axios.post(
      'http://109.228.47.242:3001/api/auth/register',
      { firstName: values.firstName,
        lastName: values.lastName,
        username: values.firstName + values.lastName,
        email: values.email,
        confirmEmail: values.confirmEmail,
        location: values.location,
        patientFirstName: values.patientFirstName,
        patientSurnameName: values.patientSurnameName,
        patientAge: values.patientAge,
        diagnosisADD: values.diagnosisADD,
        diagnosisADHD: values.diagnosisADHD,
        diagnosisAnxiety: values.diagnosisAnxiety,
        diagnososASD: values.diagnososASD,
        diagnosisAsperger: values.diagnosisAsperger,
        diagnosisAuditoryProcessing: values.diagnosisAuditoryProcessing,
        diagnosisDepression: values.diagnosisDepression,
        diagnosisDevCoordinationDisorder: values.diagnosisDevCoordinationDisorder,
        diagnosisDownSyndrome: values.diagnosisDownSyndrome,
        diagnosisEpilepsy: values.diagnosisEpilepsy,
        diagnosisFineMotor: values.diagnosisFineMotor,
        diagnosisGastro: values.diagnosisGastro,
        diagnosisGifted: values.diagnosisGifted,
        diagnosisOCD: values.diagnosisOCD,
        diagnosisSensoryIntegrationDisorder: values.diagnosisSensoryIntegrationDisorder,
        diagnosisSpecificLearningDifficulties: values.diagnosisSpecificLearningDifficulties,
        diagnosisSpeechDelays: values.diagnosisSpeechDelays,
        otherDiagnosis: values.otherDiagnosis,
        childsTreatment: values.childsTreatment,
        behaviouralTeam: values.behaviouralTeam,
        communityPaediatrician: values.communityPaediatrician,
        development: values.development,
        dieticians: values.dieticians,
        disabilitySupportWorker: values.disabilitySupportWorker,
        gastroIntestinal: values.gastroIntestinal,
        generalPaediatrician: values.generalPaediatrician,
        holisticDoctor: values.holisticDoctor,
        kinesiologist: values.kinesiologist,
        occupationalTherapist: values.occupationalTherapist,
        physiotherapist: values.physiotherapist,
        psychologist: values.psychologist,
        socialWorker: values.socialWorker,
        otherTreatment: values.otherTreatment,
        childsLookedAfterBy: values.childsLookedAfterBy,
        childsTreatmentCostPounds: values.childsTreatmentCostPounds,
        siteLikes: values.siteLikes,
        designLikes: values.designLikes,
        userServiceFeedback: values.userServiceFeedback,
        serviceLikes: values.serviceLikes,
        serviceDislikes: values.serviceDislikes,
        helpInfo: values.helpInfo,
        otherInfo: values.otherInfo,
        servicePayFeedback: values.servicePayFeedback,
        serviceRecommendFeedback: values.serviceRecommendFeedback,
        shareInfoHealthCare: values.shareInfoHealthCare,
        shareInfoSchool: values.shareInfoSchool,
        similarProduct: values.similarProduct,
        loggingInformation: values.loggingInformation,
        patientsASD: values.patientsASD,
        rating1: values.rating1,
        rating2: values.rating2,
        rating3: values.rating3,
        rating4: values.rating4,
        rating5: values.rating5,
      },
      { headers: { 'Content-Type': 'application/json' }}
    ).then(response => {
      console.log(response.data)
      navigate('/emailconfirm', {replace: true});
    })
    
  };

  return (
    <Wrapper>
      {/* <div className="container"> */}
      <div className="navWrapper ">
        <div className="nav_wrap">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </div>
        <div className="menu">
          <div className="menu_wrap">
            <Link to="/">
              <img src={close} alt="close" />
            </Link>
          </div>
        </div>
      </div>
      {/* </div> */}

      <div className="aboutCon container">
        <div className="aboutWrap">
          <div className="about_wrap_first">
            <h1>About You</h1>
            <div className="userInfoSingle">
              <p>First Name</p>
              <input
                type="text"
                className="form-input"
                placeholder="First name"
                name="_replyto"
                required
                onChange={(e) => setValues({ ...values, firstName: e.target.value})}
              />
            </div>
            <div className="userInfoSingle">
              <p>Surname</p>
              <input
                type="text"
                className="form-input"
                placeholder="Surname"
                name="_replyto"
                required
                onChange={(e) => setValues({ ...values, lastName: e.target.value})}
              />
            </div>
            <div className="userInfoSingle">
              <p>Email Address</p>
              <input
                type="text"
                className="form-input"
                placeholder="Anis374@yahoo.co.uk"
                name="_replyto"
                required
                onChange={(e) => setValues({ ...values, email: e.target.value})}
              />
            </div>
            <div className="userInfoSingle">
              <p>Confirm email address</p>
              <input
                type="text"
                className="form-input"
                placeholder="Confirm email address"
                name="_replyto"
                onChange={(e) => setValues({ ...values, confirmEmail: e.target.value})}
              />
            </div>
            <div className="userInfoSingle location">
              <p>Location</p>
              <select 
                className="minimal"
                onChange={(e) => setValues({ ...values, location: e.target.value})}
                placeholder="Choose a location"
                >
                <option value= "UK">UK</option>
                <option value= "EU">EU</option>
                <option value= "East Europe">East Europe</option>
                <option value= "USA">USA</option>
                <option value= "Canada">Canada</option>
                <option value= "Asia">Asia</option>
                <option value= "South Asia">South Asia</option>
                <option value= "Middle East">Middle East</option>
                <option value= "South America">South America</option>
                <option value= "Africa">Africa</option>  
                <option value= "Australia">Australia</option>                
                <option value= "China">China</option>             
                <option value= "Japan">Japan</option>             
                <option value= "Russia">Russia</option>
                </select>
            </div>
          </div>
          <h1>About the Patient</h1>
          <div className="userInfoSingle">
            <p>Child/Patient First name</p>
            <input
              type="text"
              className="form-input"
              placeholder="Saleem"
              name="_replyto"
              required
              onChange={(e) => setValues({ ...values, patientFirstName: e.target.value})}
            />
            </div>
            <div className="userInfoSingle">
            <p>Child/Patient Surname</p>
            <input
              type="text"
              className="form-input"
              placeholder="Rah"
              name="_replyto"
              required
              onChange={(e) => setValues({ ...values, patientSurnameName: e.target.value})}
            />
           <br />
          </div>
          <div className="userInfoSingle">
          <p>Child/Patient Age</p>
            <input
              type="text"
              className="form-input"
              placeholder="Enter age"
              name="_replyto"
              required
              onChange={(e) => setValues({ ...values, patientAge: e.target.value})}
            />
            <br />
          </div>
          <div className="rating">
              <p>Child/Patient Diagnosis</p>
              <div className="check">
              <input
                  type="checkbox"
                  name="diagnosis1"
                  value="ADD"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisADD: e.target.value})}
                />
                <label for="diagnosis1">ADD</label>
                <br />
              <input
                  type="checkbox"
                  name="diagnosis2"
                  value="ADHD"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisADHD: e.target.value})}
                />
                <label for="diagnosis2">ADHD</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis3"
                  value="Anxiety"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisAnxiety: e.target.value})}
                />
                <label for="diagnosis3">Anxiety</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis4"
                  value="ASD"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnososASD: e.target.value})}
                />
                <label for="diagnosis4">ASD</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis5"
                  value="Asperger"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisAsperger: e.target.value})}
                />
                <label for="diagnosis5">Asperger</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis6"
                  value="Auditory processing"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisAuditoryProcessing: e.target.value})}
                />
                <label for="diagnosis6">Auditory processing</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis7"
                  value="Depression"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisDepression: e.target.value})}
                />
                <label for="diagnosis7">Depression</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis8"
                  value="Development co-ordination disorder"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisDevCoordinationDisorder: e.target.value})}
                />
                <label for="diagnosis8">Development co-ordination disorder</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis9"
                  value="Down Syndrome"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisDownSyndrome: e.target.value})}
                />
                <label for="diagnosis9">Down Syndrome</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis10"
                  value="Epilepsy"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisEpilepsy: e.target.value})}
                />
                <label for="diagnosis10">Epilepsy</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis11"
                  value="Fine motor"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisFineMotor: e.target.value})}
                />
                <label for="diagnosis11">Fine motor</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis12"
                  value="Gastro"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisGastro: e.target.value})}
                />
                <label for="diagnosis12">Gastro</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis13"
                  value="Gifted"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisGifted: e.target.value})}
                />
                <label for="diagnosis13">Gifted</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis14"
                  value="OCD"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisOCD: e.target.value})}
                />
                <label for="diagnosis14">OCD</label>
                <br />

                <input
                  type="checkbox"
                  name="diagnosis15"
                  value="Sensory integration disorder"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisSensoryIntegrationDisorder: e.target.value})}
                />
                <label for="diagnosis15">Sensory integration disorder</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis16"
                  value="Specific learning difficulties"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisSpecificLearningDifficulties: e.target.value})}
                />
                <label for="diagnosis16">Specific learning difficulties</label>
                <br />
                <input
                  type="checkbox"
                  name="diagnosis17"
                  value="Speech delays"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, diagnosisSpeechDelays: e.target.value})}
                />
                <label for="diagnosis17">Speech delays</label>
                <br />

              </div>
          </div>
          <br />
          <div className="userInfoSingle location">
            <p>Other</p>
            <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Please enter text ..."
                onChange={e => setValues({ ...values, otherDiagnosis: e.target.value})}
              ></textarea>
          </div>
          <br />
          <div className="userInfoSingle location">
            <p>Any treatment in place</p>
            <select name="" className="minimal" onChange={(e) => setValues({ ...values, childsTreatment: e.target.value})}>
              <option value="Yes">Please Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="rating">
              <p>Which health professionals are looking after the patient?</p>
              <div className="check">
              <input
                  type="checkbox"
                  name="professional1"
                  value="Behavioural Team"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, behaviouralTeam: e.target.value})}
                />
                <label for="professional1">Behavioural Team</label>
                <br />
                <input
                  type="checkbox"
                  name="professional2"
                  value="Community Paediatrician"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, communityPaediatrician: e.target.value})}
                />
                <label for="professional2">Community Paediatrician</label>
                <br />
                <input
                  type="checkbox"
                  name="professional3"
                  value="Development"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, development: e.target.value})}
                />
                <label for="professional3">Development</label>
                <br />
                <input
                  type="checkbox"
                  name="professional4"
                  value="Dieticians"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, dieticians: e.target.value})}
                />
                <label for="professional4">Dieticians</label>
                <br />
                <input
                  type="checkbox"
                  name="professional5"
                  value="Disability support worker"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, disabilitySupportWorker: e.target.value})}
                />
                <label for="professional5">Disability support worker</label>
                <br />
                <input
                  type="checkbox"
                  name="professional6"
                  value="Gastrointestinal"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, gastroIntestinal: e.target.value})}
                />
                <label for="professional6">Gastrointestinal</label>
                <br />
                <input
                  type="checkbox"
                  name="professional7"
                  value="General Paediatrician"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, generalPaediatrician: e.target.value})}
                />
                <label for="professional7">General Paediatrician</label>
                <br />
                <input
                  type="checkbox"
                  name="professional8"
                  value="Holistic doctor"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, holisticDoctor: e.target.value})}
                />
                <label for="professional8">Holistic doctor</label>
                <br />
                <input
                  type="checkbox"
                  name="professional9"
                  value="Kinesiologist"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, kinesiologist: e.target.value})}
                />
                <label for="professional9">Kinesiologist</label>
                <br />
                <input
                  type="checkbox"
                  name="professional10"
                  value="OT-Occupational Therapist"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, occupationalTherapist: e.target.value})}
                />
                <label for="professional10">OT-Occupational Therapist</label>
                <br />
                <input
                  type="checkbox"
                  name="professional11"
                  value="Physiotherapist"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, physiotherapist: e.target.value})}
                />
                <label for="professional11">Physiotherapist</label>
                <br />
                <input
                  type="checkbox"
                  name="professional12"
                  value="Psychologist"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, psychologist: e.target.value})}
                />
                <label for="professional12">Psychologist</label>
                <br />
                <input
                  type="checkbox"
                  name="professional13"
                  value="Social Worker"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, socialWorker: e.target.value})}
                />
                <label for="professional14">Social Worker</label>
              </div>
          </div>
          <br />
          <div className="userInfoSingle location">
            <p>Other</p>
            <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Please enter text ..."
                onChange={e => setValues({ ...values, otherTreatment: e.target.value})}
              ></textarea>
          </div>
          <br />
          <div className="userInfoSingle">
            <p>Do you have any carers who cares for the patient?</p>
            <select name="" className="minimal" onChange={(e) => setValues({ ...values, childsLookedAfterBy: e.target.value})}>
            <option value="Private">Please Select</option>
              <option value="None">None</option>
              <option value="Private">Private</option>
              <option value="Local Authorities">Local Authorities</option>
              <option value="NHS">NHS</option>
              </select>
          </div>

          <div className="userInfoSingle location">
            <p>
              How much money in average monthly spent on patients
              treatment, activities and equipments
            </p>
            <select name="" className="minimal" onChange={(e) => setValues({ ...values, childsTreatmentCostPounds: e.target.value})}>
              <option value="0-49">Please Select</option>
              <option value="0-49">£0-49</option>
              <option value="50-99">£50-99</option>
              <option value="100-199">£100-199</option>
              <option value="200-299">£200-299</option>
              <option value="300-499">£300-499</option>
              <option value="500-999">£500-999</option>
              <option value="1000 and above">£1000 and above</option>
            </select>
          </div>

          <div className="quesWrapLeft">
            <h1>Questions about this site</h1>
            <div className="userInfoSingle location">
              <p>What do you think about this site</p>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Please enter text ..."
                onChange={e => setValues({ ...values, siteLikes: e.target.value})}
              ></textarea>
            </div>
            <div className="userInfoSingle location">
              <p>What do you think about the design</p>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Please enter text ..."
                onChange={e => setValues({ ...values, designLikes: e.target.value})}
              ></textarea>
            </div>

            <div className="userInfoSingle location">
              <p>Would you use this service</p>
              <select name="" className="minimal" onChange={(e) => setValues({ ...values, userServiceFeedback: e.target.value})}>
                <option value="Yes">Please Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="userInfoSingle">
              <p>What do you like about this service the most?</p>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Please enter text ..."
                onChange={e => setValues({ ...values, serviceLikes: e.target.value})}
              ></textarea>
            </div>

            <div className="userInfoSingle">
              <p>What do you dislike about this service the most?</p>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Please enter text ..."
                onChange={e => setValues({ ...values, serviceDislikes: e.target.value})}
              ></textarea>
            </div>

            <div className="userInfoSingle">
              <p>
                How will this app help you, what does it mean for you and your child/Patient
              </p>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Please enter text ..."
                onChange={e => setValues({ ...values, helpInfo: e.target.value})}
              ></textarea>
            </div>

            <div className="userInfoSingle">
              <p>
                Is there anything else important to you and your child you would
                like us to add to this site.
              </p>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Please enter text ..."
                onChange={e => setValues({ ...values, otherInfo: e.target.value})}
              ></textarea>
            </div>

            <div className="userInfoSingle location">
              <p>How much are you willing to pay for this service.</p>
              <select name="" className="minimal" onChange={(e) => setValues({ ...values, servicePayFeedback: e.target.value})}>
                <option value="Free">Please Select</option>
                <option value="Free">Free</option>
                <option value="£5-£10">£5-£10</option>
                <option value="£10-£20">£10-£20</option>
                <option value="£20-£30">£20-£30</option>
                <option value="£40-£50">£40-£50</option>
              </select>
            </div>
            <div className="userInfoSingle location">
              <p>Would you recommend this app</p>
              <select name="" className="minimal" onChange={(e) => setValues({ ...values, serviceRecommendFeedback: e.target.value})}>
                <option value="Yes">Please Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Maybe">Maybe</option>
              </select>
            </div>


            <div className="userInfoSingle location">
              <p>Would you share this site or patients log with your health care 
                professional</p>
              <select name="" className="minimal" onChange={(e) => setValues({ ...values, shareInfoHealthCare: e.target.value})}>
                <option value="Yes">Please Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Maybe">Maybe</option>
              </select>
            </div>

            <div className="userInfoSingle location">
              <p>Would you share this site or patients log with your school</p>
              <select name="" className="minimal" onChange={(e) => setValues({ ...values, shareInfoSchool: e.target.value})}>
                <option value="Yes">Please Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Maybe">Maybe</option>
              </select>
            </div>

            <div className="userInfoSingle location">
              <p>Are you using any similar product already</p>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Please enter text ..."
                onChange={e => setValues({ ...values, similarProduct: e.target.value})}
              ></textarea>
            </div>

            <div className="userInfoSingle location">
              <p>Are you logging information already? if so how?</p>
              <select name="" className="minimal" onChange={(e) => setValues({ ...values, loggingInformation: e.target.value})}>
                <option value="Physical paper">Please Select</option>
                <option value="Physical paper">Physical paper</option>
                <option value="Office or other applications">Office or other applications</option>
                <option value="Online software">Online software</option>
              </select>
            </div>

            <div className="userInfoSingle location">
              <p>How many ASD patients do you know directly</p>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Please enter text ..."
                onChange={e => setValues({ ...values, patientsASD: e.target.value})}
              ></textarea>
            </div>
            <div className="rating">
              <p>Rate this App</p>
              <div className="check">
                <input
                  type="checkbox"
                  name="1_rating"
                  value="1"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, rating1: e.target.value})}
                />
                <label for="1_rating"> 1 Poor</label>
                <br />
                <input
                  type="checkbox"
                  name="2_rating"
                  value="2"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, rating2: e.target.value})}
                />
                <label for="2_rating"> 2</label>
                <br />
                <input
                  type="checkbox"
                  name="3_rating"
                  value="3"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, rating3: e.target.value})}
                />
                <label for="3_rating"> 3 OK</label>
                <br />
                <input
                  type="checkbox"
                  name="4_rating"
                  value="4"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, rating4: e.target.value})}
                />
                <label for="4_rating"> 4</label>
                <br />
                <input
                  type="checkbox"
                  name="5_rating"
                  value="5"
                  className="checkbox"
                  onChange={(e) => setValues({ ...values, rating5: e.target.value})}
                />
                <label for="5_rating"> 5 Excellent </label>
              </div>
            </div>

            <Link to="/emailconfirm" className="submit_btn">
              <button onClick={formSubmit}>Submit</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="footer_desk">
        <Footer />
      </div>
      <div className="footerM_mob">
        <FooterMobile />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .navWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    .nav_wrap {
      width: 20%;
      text-align: -webkit-right;
      .logo {
        width: 260px;
        height: 81px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .menu {
      width: 80%;
      .menu_wrap {
        width: 1200px;
        margin: 0 auto;
        justify-content: end;
        display: flex;
        img {
          cursor: pointer;
        }
      }
    }
  }

  .aboutCon {
    width: 970px;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 103.79px;
    margin-bottom: 50px;
    .about_wrap_first {
      margin-bottom: 100px;
    }

    h1 {
      text-align: left;
      font: normal normal bold 30px/34px Arial;
      letter-spacing: 0px;
      color: #4f4f4f;
      opacity: 1;
      margin-bottom: 33.41px;
    }

    .quesWrapLeft {
      margin-top: 90px;

      .submit_btn {
        width: 388px;
        text-align: center;
        button {
          background: #2699fb 0% 0% no-repeat padding-box;
          border-radius: 20px;
          font: normal normal bold 14px/17px Arial;
          letter-spacing: 0px;
          color: #ffffff;
          width: 286px;
          height: 39px;
          cursor: pointer;
          outline: none;
          border: none;
          margin: 93px 0 0 50px;
          animation: pulse 1.2s ease infinite;
        }
        button:hover {
          animation: none;
        }
      }
    }

    .userInfoSingle {
      margin-bottom: 31.41px;
      p {
        text-align: left;
        font: normal normal normal 20px/24px Arial;
        letter-spacing: 0px;
        color: #2699fb;
        opacity: 1;
        width: 383px;
      }
      h6 {
        text-align: left;
        font: normal normal normal 14px/14px Arial;
        letter-spacing: 0px;
        color: #4f4f4f;
        opacity: 1;
        margin: 5px 0 14px 0;
      }
      span {
        display: block;
        font: normal normal normal 16px/24px Arial;
        letter-spacing: 0px;
        color: #4f4f4f;
        opacity: 1;
        margin-top: 2px;
      }
      input {
        background: #f1f9ff 0% 0% no-repeat padding-box;
        border: 1px solid #f1f9ff;
        opacity: 1;
        padding: 0 14.63px;
        width: 418px;
        height: 34px;
        margin-top: 5px;
        border: none;
        outline: none;
      }
      select {
        background: #f1f9ff 0% 0% no-repeat padding-box;
        border: 1px solid #f1f9ff;
        opacity: 1;
        padding: 0 14.63px;
        width: 418px;
        height: 34px;
        margin-top: 5px;
        border: none;
        outline: none;
        -webkit-appearance: none;
        font: normal normal normal 16px/24px Arial;
        color: #4f4f4f;

        option {
          background-color: #fff;
          text-align: left;
          font: normal normal normal 16px/24px Arial;
          letter-spacing: 0px;
          color: #4f4f4f;
          opacity: 1;
        }
      }
 
      textarea {
        width: 418px;
        height: 163px;
        text-align: left;
        font: normal normal bold 16px/24px Arial;
        letter-spacing: 0px;
        color: #4f4f4f;
        background: #f1f9ff 0% 0% no-repeat padding-box;
        border: 1px solid #f1f9ff;
        opacity: 1;
        outline: none;
        margin-top: 10px;
        padding: 0 14px;
      }

      select.minimal {
        background-image: linear-gradient(45deg, transparent 50%, #2699fb 50%),
          linear-gradient(135deg, #2699fb 50%, transparent 50%);
        background-position: calc(100% - 20px) calc(1em + 2px),
          calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        background-repeat: no-repeat;
      }

      select.minimal:focus {
        background-image: linear-gradient(45deg, #2699fb 50%, transparent 50%),
          linear-gradient(135deg, transparent 50%, #2699fb 50%),
          linear-gradient(to right, #2699fb, #2699fb);
        background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
          calc(100% - 2.5em) 0.5em;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        background-repeat: no-repeat;
        outline: 0;
      }

      select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
      }
    }
    .rating {
      p {
        text-align: left;
        font: normal normal normal 20px/24px Arial;
        letter-spacing: 0px;
        color: #2699fb;
        opacity: 1;
        width: 383px;
      }
      .check {
        width: 383px;
        margin-top: 25px;
        input {
          background: #2699fb 0% 0% no-repeat padding-box !important;
          opacity: 1;
          margin-top: 5px;
        }
        label {
          font: normal normal normal 18px/24px Arial;
          letter-spacing: 0px;
          color: #2699fb;
          opacity: 1;
          margin-left: 26px;
        }
      }
    }
  }

  .footer_desk {
    display: block;
  }
  .footerM_mob {
    display: none;
  }

  @media only screen and (max-width: 1024px) and (min-width: 244px) {
    .navWrapper {
      padding: 29px !important;
    }
    .nav_wrap {
      width: 0 !important;
      .logo {
        width: 107px !important;
        height: 33px !important;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .menu {
      width: 25px !important;
      .menu_wrap {
        width: 28px !important;
        height: 28px !important;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .aboutWrap {
      margin-bottom: 70px;
    }
    .container {
      padding: 0px 29px;
    }
    .aboutCon {
      h1 {
        font: normal normal bold 24px/28px Arial !important;
      }
    }

    .userInfoSingle {
      margin-bottom: 31.41px;
      p {
        width: 266px !important;
        font: normal normal normal 16px/24px Arial !important;
      }
      h6 {
        font: normal normal normal 12px/14px Arial !important;
      }
      span {
        display: block;
        font: normal normal normal 14px/24px Arial !important;
      }
      input {
        width: 100% !important;
      }
      select {
        width: 100% !important;
      }
      textarea {
        width: 100% !important;
      }
    }
    .about_wrap_first {
      margin-bottom: 40px !important;
    }
    .quesWrapLeft {
      margin-top: 20px !important;
    }

    .rating {
      p {
        width: 100% !important;
        font: normal normal normal 16px/24px Arial !important;
      }
    }

    button {
      font: normal normal bold 14px/17px Arial;
      margin: 60px 0 0 0 !important;
      width: 100% !important;
    }

    .footer_desk {
      display: none;
    }
    .footerM_mob {
      display: block;
    }
  }
`;

export default SignUp;
